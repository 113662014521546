<template>
  <div>
    <el-dialog
      @close="closeMap"
      :before-close="closeMap"
      title="获取经纬度"
      center
      :visible.sync="jingweiVisible"
      width="700px" top="5vh">
      
      <baidu-map
        style="display:flex;flex-direction: column-reverse;" 
        id="allmap"
        @ready="mapReady"
        @click="getLocation"
        :scroll-wheel-zoom="true"
      >
        <div style="display:flex;justify-content:center;margin:15px">
          <bm-auto-complete v-model="searchJingwei" :sugStyle="{zIndex: 999999}">
            <el-input v-model="searchJingwei" style="width:300px;margin-right:15px" placeholder="输入地址"></el-input>
          </bm-auto-complete>
          <el-button type="primary" @click="getBaiduMapPoint">搜索</el-button>
        </div>
        <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
        <bm-marker v-if="infoWindowShow" :position="{lng: longitude, lat: latitude}">
          <bm-label content="" :labelStyle="{color: 'red', fontSize : '24px'}" :offset="{width: -35, height: 30}"/>
        </bm-marker>
        <bm-info-window :position="{lng: longitude, lat: latitude}" :show="infoWindowShow" @clickclose="infoWindowClose">
          <p>纬度:{{this.latitude}}</p>
          <p>经度:{{this.longitude}}</p>
        </bm-info-window>
      </baidu-map>
      <div style="text-align:center">
        <el-button type="default" @click="cancelJingwei">取消</el-button>
        <el-button type="primary" @click="subJingwei">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props:{
    jingweiVisible:{
      type:Boolean,
      default(){
        return false
      }
    },
  },
  data () {
    return {
      searchJingwei:'',
      infoWindowShow:false,
      longitude:'',
      latitude:'',
      point:''
    }
  },
  methods: {
    //地图初始化
    mapReady({ BMap, map }) {
      // 选择一个经纬度作为中心点
      this.point = new BMap.Point(113.27, 23.13);
      map.centerAndZoom(this.point, 12);
      this.BMap=BMap
      this.map=map
    },
    //点击获取经纬度
    getLocation(e){
      this.longitude=e.point.lng
      this.latitude=e.point.lat
      this.infoWindowShow=true
    },
    getBaiduMapPoint(){
      let that=this
      let myGeo = new this.BMap.Geocoder()
      myGeo.getPoint(this.searchJingwei,function(point){
        if(point){
          that.map.centerAndZoom(point,15)
          that.latitude=point.lat
          that.longitude=point.lng
          that.infoWindowShow=true
        }

      })
    },
    //信息窗口关闭
    infoWindowClose(){
      this.latitude=''
      this.longitude=''
      this.infoWindowShow=false
    },
    //经纬度取消
    cancelJingwei(){
      this.latitude=''
      this.longitude=''
      this.searchJingwei=''
      this.$emit('close')
    },
    subJingwei(){
      this.$emit('subJINGWEI',this.latitude,this.longitude)
    },
    closeMap(){
      this.latitude=''
      this.longitude=''
      this.searchJingwei=''
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
#allmap{
  height: 450px;
  width: 100%;
  margin: 10px 0;
}
</style>